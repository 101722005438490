.size-small {
  --button-size: 24px;
  --button-font-size: 12px;
  --button-font-weight: 400;

  padding-block: calc(var(--button-size) / 8);
  padding-inline: calc(var(--button-size) / 2);
}

.size-normal {
  --button-size: 32px;
  --button-font-size: var(--dimensions-font-size-button-normal);
  --button-font-weight: 700;

  padding-block: calc(var(--button-size) / 5);
  padding-inline: calc(var(--button-size) / 3);
}

.size-large {
  --button-size: 80px;
  --button-font-size: 17px;
  --button-font-weight: 700;

  padding-block: calc(var(--button-size) / 8);
  padding-inline: calc(var(--button-size) / 2);
}

.color-primary {
  --button-main-color: var(--palette-primary-main);
  --button-interaction-color: var(--palette-primary-dark);
  --button-contrast-color: var(--palette-primary-contrast-text);
}

.color-secondary {
  --button-main-color: var(--palette-secondary-main);
  --button-interaction-color: var(--palette-secondary-dark);
  --button-contrast-color: var(--palette-secondary-contrast-text);
}

.color-accent {
  --button-main-color: var(--palette-accent-main);
  --button-interaction-color: var(--palette-accent-dark);
  --button-contrast-color: var(--palette-accent-contrast-text);
}

.root {
  font-size: var(--button-font-size);
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  font-weight: var(--button-font-weight);
  text-transform: uppercase;
  transition-duration: var(--animation-duration);
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}

.type-primary {
  border-radius: var(--button-size);
  background-color: var(--button-main-color);
  color: var(--button-contrast-color);
  border: 1px solid var(--button-main-color);
  transition-property: background-color, color;

  &:hover {
    background-color: var(--button-interaction-color);
    box-shadow: var(--shadow-default);
  }
}
a.type-primary {
  color: var(--button-contrast-color);
}
.type-secondary {
  border-radius: var(--button-size);
  background-color: var(--button-contrast-color);
  border: 2px solid var(--button-main-color);
  color: var(--button-main-color);
  transition-property: background-color, color, border;

  &:hover {
    box-shadow: var(--shadow-default);
  }
}
a.type-secondary {
  color: var(--button-main-color);
}

.type-tertiary {
  border-radius: var(--button-size);
  color: var(--button-main-color);
  &:hover {
    color: var(--button-interaction-color);
  }
}
a.type-secondary {
  color: var(--button-main-color);
}

.type-text {
  padding: 0;
  font-weight: bold;
  color: var(--button-main-color);
}
