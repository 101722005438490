@import 'styles/mixins';

.root {
  background-color: var(--palette-background-secondary);
  box-shadow: var(--shadow-default);
  border-block-end: 1px solid var(--palette-divider);

  flex-direction: column;
  display: flex;
  color: var(--palette-text-primary);
  position: -webkit-sticky;
  position: sticky;
  inset-block-start: 0;
  width: 100%;
  z-index: 45;

  a {
    text-decoration: none;
  }

  .upperHeaderContainer {
    width: 100%;
    display: flex;
    padding: 0;
    z-index: 1;
    position: relative;
    > nav {
      width: 100%;
      align-items: center;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
  }

  .upperHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include container-full-width;
  }

  .dropdownOnHover:hover .dropdownOnHover {
    opacity: 1;
    clip-path: inset(-12px -12px);
  }

  .extLinkStyle {
    color: var(--palette-text-secondary);
    font-size: var(--dimensions-font-size-normal);
    text-transform: capitalize;
  }
}

.logo {
  flex: 0 0;

  display: inline-block;
  flex-basis: 95px;
  height: 100%;
  max-width: 150px;
  padding-block: 4px;
  padding-inline: 0;
}

.navbar {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-inline-start: 20px;
}

.signout {
  text-transform: uppercase;
  border-block-start: 1px solid var(--palette-divider);
}

.dashboardMenu {
  display: none;
}

@include under-sm {
  .navbar {
    display: none;
  }
  .dashboardMenu {
    display: block;
  }
}

.mobileMenuBtn {
  max-width: 70px;
}
