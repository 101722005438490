@import 'styles/mixins';

.root {
  position: relative;
}

.selector {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--palette-primary-main);
  border-radius: 25px;

  .icon {
    width: 1rem;
    @include md {
      margin-inline-start: 0.5rem;
      margin-inline-end: 0.5rem;
    }
  }

  .selectorListItemLanguage {
    display: none;
    @include md {
      display: inline;
    }
  }
}

.list {
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: calc(100% + 1rem);
  min-height: 3.125rem;
  max-height: 50vh;
  overflow-y: auto;

  @include md {
    inset-inline-end: initial;
  }
}
