.root {
  font-family: var(--font-default);
  color: var(--palette-text-primary);
  font-size: var(--dimensions-font-size-normal);
  line-height: 1.42857143;
  font-weight: normal;
}

.normal {
  font-size: var(--dimensions-font-size-normal);
}
.large {
  font-size: var(--dimensions-font-size-large);
}

.primary {
  color: var(--palette-text-primary);
}
.secondary {
  color: var(--palette-text-secondary);
}
.default {
  color: var(--palette-text-default);
}
.bold {
  font-weight: bold;
}

.markdown {
  li {
    margin-block: 0.8rem;
    margin-inline: 0;
    line-height: 1.3;
  }
  p:first-child {
    margin-block-start: 0;
  }
  p:last-child {
    margin-block-end: 0;
  }
}
